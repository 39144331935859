<template>
  <div>
    <a-card title="新增工序检验">
      <template slot="extra" style="margin: -4px 0">
        <a-button type="primary" icon="left" ghost @click="$router.go(-1)">返回</a-button>
      </template>

      <a-form-model ref="form" :model="dataForm" :rules="dataRules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="number" label="工序检验单号">
              <a-input v-model="dataForm.number" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="production_order" label="生产工单">
              <ProductionOrderSelect v-model="dataForm.production_order" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_process" label="检验工序">
              <a-input v-model="dataForm.inspection_process" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_date" label="检验日期">
              <a-date-picker v-model="dataForm.inspection_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspector" label="检验人">
              <a-select v-model="dataForm.inspector" style="width: 100%">
                <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_result" label="检验结果">
              <a-select v-model="dataForm.inspection_result" style="width: 100%">
                <a-select-option value="qualified">合格</a-select-option>
                <a-select-option value="unqualified">不合格</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="qualified_quantity" label="合格数量">
              <a-input-number v-model="dataForm.qualified_quantity" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="unqualified_quantity" label="不合格数量">
              <a-input-number v-model="dataForm.unqualified_quantity" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="inspection_content" label="检验报告">
              <a-input v-model="dataForm.inspection_content" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="dataForm.remark" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6" style="width: 320px">
            <a-form-item prop="process_inspection_files" label="附件">
              <ProcessInspectionFileUpload v-model="dataForm.process_inspection_files" :initialItems="[]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-model>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="createLoading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { processInspectionReportNumber, processInspectionReportCreate } from "@/api/inspection";
import { userOption } from "@/api/option";
import moment from "moment";

export default {
  components: {
    ProductionOrderSelect: () => import("@/components/ProductionOrderSelect"),
    ProcessInspectionFileUpload: () => import("@/components/ProcessInspectionFileUpload"),
  },
  data() {
    return {
      dataForm: {},
      dataRules: {
        number: [
          { required: true, message: "请输入检验报告单号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        production_order: [{ required: true, message: "请选择生产工单", trigger: "change" }],
        inspection_process: [
          { required: true, message: "请输入检验工序", trigger: "change" },
          { max: 64, message: "超出最大长度 (64)", trigger: "change" },
        ],
        inspection_date: [{ required: true, message: "请选择检验日期", trigger: "change" }],
        inspector: [{ required: true, message: "请选择检验人", trigger: "change" }],
        inspection_result: [{ required: true, message: "请选择检验结果", trigger: "change" }],
        qualified_quantity: [{ required: true, message: "请输入合格数量", trigger: "change" }],
        unqualified_quantity: [{ required: true, message: "请输入不合格数量", trigger: "change" }],
        inspection_content: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
        remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
      },

      userItems: [],
      createLoading: false,
    };
  },
  computed: {
    operator() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    initData() {
      userOption({ page_size: 999999, is_active: true }).then((data) => {
        this.userItems = data.results;
      });

      processInspectionReportNumber().then((data) => {
        this.dataForm = { ...this.dataForm, number: data.number, unqualified_quantity: 0 };
      });

      this.dataForm.inspection_date = moment().format("YYYY-MM-DD");
      this.dataForm.inspector = this.operator;
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const dataForm = { ...this.dataForm };

          this.createLoading = true;
          processInspectionReportCreate(dataForm)
            .then(() => {
              this.$message.success("创建成功");
              this.$router.go(-1);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
